.navbar_container {
  position: fixed;
  z-index: 11;
  padding: 10px;
  top: 0;
  background-color: rgba(17, 17, 18, 0.7);
  backdrop-filter: blur(calc(var(--blur-value) + 3px));
  -webkit-backdrop-filter: blur(calc(var(--blur-value) + 3px));
  overflow: hidden;
  width: 100%;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin: 0 auto;
  width: 50%;
}

.navbar_item a {
  padding: 10px;
  text-align: center;
  font-size: 0.8em;
  transition: 150ms;
  color: var(--navbar-text-color);
  text-decoration: none;
}

.navbar_item a:visited {
  color: var(--navbar-text-color);
}

.navbar_item :hover {
  color: var(--navbar-hover-color);
}

.home {
  width: 2em;
  overflow: hidden;
  margin-top: 10px;
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (max-width:1024px) {
  .home {
    object-fit: cover;
    padding: 0;
    padding-top: 20px;
    height: 100px;
    width: 100px;

  }

  .navbar_container {
    z-index: 11;
    padding: 0;
  }

  .menu_bars {
    position: absolute;
    inset: 0;
    height: 50px;
    width: 50px;
    margin: 0 auto;
    top: 7px;
  }

  .navbar {

    flex-direction: column;
    text-align: center;
    margin-top: 50px;
  }

  .navbar_item a {
    display: none;
  }

  .navbar_item_active {

    padding-bottom: 5px;
  }

  .navbar_item_active a {
    text-align: center;
    font-size: 0.7em;
    transition: 150ms;
    color: var(--navbar-text-color);
    text-decoration: none;
  }

  .navbar_item_active a:visited {
    color: var(--navbar-text-color);
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: var(--navbar-hover-color);
    margin: 6px auto;
    transition: 0.4s;
  }

  .change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
  }
}