.grid_container {
  padding-top: 15vh;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 1fr;
  gap: 80px;
  justify-content: center;
  align-items: center;
}

.grid_left {
  position: relative;
  text-align: center;

  h1,
  h3 {
    margin-bottom: 5px;
  }
}

.grid_right p {
  font-size: 1em;
  line-height: 1.1em;
}

.portrait {
  border-radius: 50%;
  object-fit: cover;
  width: var(--portrait-size);
  height: var(--portrait-size);
}

@media only screen and (max-width: 900px) {
  .grid_container {
    padding-top: 0vh;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 0;
    overflow: show;
    grid-template-columns: 1fr;
    grid-template-rows: 50% 50%;
  }

  .grid_left {
    padding-bottom: 0;
    margin: 0 auto;

    h1 {
      font-size: 1.8em;
    }

    h3 {
      font-size: 1em;
    }
  }

  .grid_right {
    padding: 0;
    padding-top: 5vh;
    margin: 0 5vw;
    text-align: center;
  }

}